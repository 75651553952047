@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 0.875rem;
    @apply bg-capBeige text-capGreen w-full font-semibold;
  }
  :root {
    --navbar-height: 9rem;
    --detailsbar-height: 5.5rem;
  }

  @media (max-width: 768px) {
    :root {
      --navbar-height: 5rem;
    }
  }

  .margin {
    @apply px-[3%] md:px-[4%] lg:px-[7%];
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  @apply bg-white;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  @apply bg-capGreen;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* chart styles from flowbite */
.apexcharts-tooltip {
  @apply bg-white dark:bg-gray-700 text-capGreen dark:text-gray-400 border border-capGreen rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply py-2 px-4 bg-gray-100 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-500 !important;
}

.apexcharts-xaxistooltip {
  @apply text-gray-500 border-0 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  @apply dark:text-white;
}

.apexcharts-xaxistooltip-text {
  @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-green-500 !important;
}
